import React from "react";
import { User } from "api/types/user";
import { Dealer } from "api/types/dealer";
import { useAppSelector } from "store";
import { Box, Typography, Card, CardContent } from "@mui/material";

const AdvisorUserInfoSection = () => {
  const user: User = useAppSelector((state) => state.user);
  const dealer: Dealer = useAppSelector((state) => state.dealer);

  return (
    <Card variant="outlined" sx={{ mb: 2 }}>
      <CardContent>
        <Box>
          <Typography variant="body1">
            <strong>First Name:</strong> {user.FirstName}
          </Typography>
          <Typography variant="body1">
            <strong>Last Name:</strong> {user.LastName}
          </Typography>
          <Typography variant="body1">
            <strong>Dealer Name:</strong> {dealer.Name}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

export default AdvisorUserInfoSection;
