import React from "react";
import DealerPreferredOEMSection from "components/DealerForm/components/DealerPreferredOEMSection";
import DealerHolidaySection from "components/DealerForm/components/DealerHolidaySection";
import DealerScheduleSection from "components/DealerForm/components/DealerScheduleSection";
import LoanerVehicleSection from "components/DealerForm/components/LoanerVehicleSection";
import AmenitySection from "components/DealerForm/components/AmenitySection";

import DealerAdvisorScheduleConfiguration from "components/DealerForm/DealerAdvisorScheduleConfiguration";
const AppointmentSchedulerConfigurationForm = () => {
  return (
    <>
      <h1> Dealer Preferred Makes</h1>
      <DealerPreferredOEMSection />
      <h1> Dealer Schedule </h1>
      <DealerScheduleSection />
      <h1> Dealer Advisor Schedule</h1>
      <DealerAdvisorScheduleConfiguration/>
      <h1> Dealer Holidays</h1>
      <DealerHolidaySection/>
      <h1> Loaner Vehicles</h1>
      <LoanerVehicleSection/>
      <h1> Dealer Amenities</h1>
      <AmenitySection/>
    </>
  );
};

export default AppointmentSchedulerConfigurationForm;