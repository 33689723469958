import { GroupServiceLine } from "classes/ServiceLine"
import { SchedulerMaintenanceLineItemSource } from "api/types/appointmentScheduleLineItem"
import { DealerServiceItem, Service, ServiceIntervalItem } from "api/types/maintenanceResponse"

export default function buildDealerMaintenanceSection({
    previous,
    current,
  }: Service<DealerServiceItem>): GroupServiceLine[] {
    const mapItems = (group: ServiceIntervalItem<DealerServiceItem>) => {
      const interval = group.interval
  
      return group.items.map((item, index) => ({
        id: index,
        description: item.description,
        price: item.pricing.value,
        comment: item.pricing.comment,
        distance: interval,
        source: SchedulerMaintenanceLineItemSource.DEALER
      }))
    }
  
    return [
      {
        id: previous.interval,
        title: `Recent ${new Intl.NumberFormat().format(previous.interval)} Miles`,
        items: mapItems(previous),
      },
      {
        id: current.interval,
        title: `Current ${new Intl.NumberFormat().format(current.interval)} Miles`,
        items: mapItems(current),
      },
    ].filter(group => group.id > 0 && group.items.length > 0)
  }