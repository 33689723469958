import React, {
  useEffect,
  useState,
} from "react";
import {
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from "@mui/material";
import { useAppSelector } from "store";
import { Dealer } from "api/types/dealer";
import pages from "pages";
import { useDispatch } from "react-redux";
import { setDealer, updateValidation } from "modules/dealerForm";
import LoadingIndicator from "components/LoadingIndicator";
import {
  TextMaskedInput,
  TextMaskedInputType,
} from "componentsUI/MaskedInputs";
import styles from "./styles.module.scss";
import NumberFormat from "react-number-format";

export default function Form() {
  const dispatch = useDispatch();
  const { dealer, isLoading, validation } = useAppSelector((state) => state.dealerForm);

  const DEALER_EXTERNAL_ID_NAME = `ExternalID`
  const DEALER_NAME_NAME = `Name`

  const title = dealer.ID
    ? pages.DEALERS_EDIT_FORM.title
    : pages.DEALERS_CREATE_FORM.title;

  const [formLoading, setFormLoading] = useState(isLoading);

  useEffect(() => {
    setFormLoading(isLoading);
  }, [isLoading]);

  const changeHandler = (e) => {
    const name = e.target.name
    const value = e.target.value

    const currentDealer = {
      ...dealer,
      [name]: value,
    } as Dealer;

    dispatch(setDealer(currentDealer));

    updateStateValidation(name, value)
  };

  const updateStateValidation = (componentName: string, newValue: any) => {
    switch(componentName) {
      case DEALER_EXTERNAL_ID_NAME:
        dispatch(updateValidation({
          ...validation,
          externalIDHasError: !(newValue !== ``),
        }))
        break
      case DEALER_NAME_NAME:
        dispatch(updateValidation({
          ...validation,
          dealerNameHasError: !(newValue !== ``),
        }))
        break
    }
  }

  const checkedChangedHandler = (e) => {
    const name = e.target.name
    const checked = e.target.checked

    const currentDealer = {
      ...dealer,
      [name]: checked,
    } as Dealer;

    dispatch(setDealer(currentDealer));
  }

  //console.log(validation)
  return (
    <>
      {formLoading ? (
        <LoadingIndicator />
      ) : (
        <form noValidate>
          <h1>{title}</h1>
          <Grid container spacing={2} sx={{ width: "inherited" }}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                required
                label="Activation Key"
                value={dealer.ExternalID}
                name={DEALER_EXTERNAL_ID_NAME}
                onChange={changeHandler}
                onBlur={changeHandler}
                error={validation.externalIDHasError}
                disabled={
                  dealer?.ID === 0 || dealer?.ID === undefined
                    ? false
                    : true
                }
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                fullWidth
                label="RM Dealer ID"
                name="RMDealerID"
                value={dealer.RMDealerID}
                onChange={changeHandler}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                fullWidth
                type="number"
                name="AutoSendEstimateThresholdInMinutes"
                value={dealer.AutoSendEstimateThresholdInMinutes}
                onChange={changeHandler}
                className={styles.numberInput}
                label="Auto Send Estimate Threshold In Minutes"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                required
                label="Name"
                value={dealer.Name}
                name={DEALER_NAME_NAME}
                error={validation.dealerNameHasError}
                onChange={changeHandler}
                onBlur={changeHandler}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Address Line 1"
                name="Address1"
                value={dealer.Address1}
                onChange={changeHandler}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                name="Address2"
                label="Address Line 2"
                value={dealer.Address2}
                onChange={changeHandler}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                id="outlined-required"
                label="City"
                name="City"
                value={dealer.City}
                onChange={changeHandler}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                label="State"
                name="Region"
                onChange={changeHandler}
                value={dealer?.Region}
                InputProps={{
                  inputComponent: TextMaskedInput as any,
                }}
                inputProps={{
                  mask: `aa`,
                  regex: /^[A-Z]{2}$/,
                  type: TextMaskedInputType.State,
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <NumberFormat
                fullWidth
                mask={``}
                customInput={TextField}
                format={`#####`}
                label="Postal Code"
                onChange={changeHandler}
                value={dealer?.PostalCode}
                name={`PostalCode`}/>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth>
                <InputLabel id="time-zone-select-label">Time Zone</InputLabel>
                <Select
                  fullWidth
                  label="Time Zone"
                  value={dealer?.TimeZoneID || ``}
                  onChange={changeHandler}
                  name="TimeZoneID"
                >
                  <MenuItem value={`Pacific Standard Time`}>
                    Pacific Standard Time
                  </MenuItem>
                  <MenuItem value={`Mountain Standard Time`}>
                    Mountain Standard Time
                  </MenuItem>
                  <MenuItem value={`Central Standard Time`}>
                    Central Standard Time
                  </MenuItem>
                  <MenuItem value={`Eastern Standard Time`}>
                    Eastern Standard Time
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <NumberFormat
                fullWidth
                mask={``}
                customInput={TextField}
                format={`(###) ###-####`}
                label="Sales Phone Number"
                onChange={changeHandler}
                value={dealer?.SalesPhoneNumber}
                name={`SalesPhoneNumber`}/>
            </Grid>
            <Grid item xs={4}>
              <NumberFormat
                fullWidth
                mask={``}
                customInput={TextField}
                format={`(###) ###-####`}
                label="Service Phone Number"
                onChange={changeHandler}
                value={dealer?.ServicePhoneNumber}
                name={`ServicePhoneNumber`}/>
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                sx={{ width: "100%", justifyContent: "space-between", ml: "0" }}
                control={
                  <Switch
                    checked={dealer?.EnableAdvisorPINs}
                    value={dealer?.EnableAdvisorPINs}
                    onChange={checkedChangedHandler}
                  />
                }
                name="EnableAdvisorPINs"
                label="Enable Advisor PINs"
                labelPlacement="start"
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                sx={{ width: "100%", justifyContent: "space-between", ml: "0" }}
                control={
                  <Switch
                    checked={dealer?.EnableAppointments}
                    value={dealer?.EnableAppointments}
                    onChange={checkedChangedHandler}
                  />
                }
                name="EnableAppointments"
                label="Enable Appointments"
                labelPlacement="start"
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                sx={{ width: "100%", justifyContent: "space-between", ml: "0" }}
                control={
                  <Switch
                    checked={dealer?.EnableBDC}
                    value={dealer?.EnableBDC}
                    disabled={true}
                    onChange={checkedChangedHandler}
                  />
                }
                name="EnableBDC"
                label="Enable BDC"
                labelPlacement="start"
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                sx={{ width: "100%", justifyContent: "space-between", ml: "0" }}
                control={
                  <Switch
                    checked={dealer?.EnableCommunicationDashboard}
                    value={dealer?.EnableCommunicationDashboard}
                    onChange={checkedChangedHandler}
                  />
                }
                name="EnableCommunicationDashboard"
                label="Enable Communication Dashboard"
                labelPlacement="start"
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                sx={{ width: "100%", justifyContent: "space-between", ml: "0" }}
                control={
                  <Switch
                    checked={dealer?.EnableCRM}
                    value={dealer?.EnableCRM}
                    onChange={checkedChangedHandler}
                  />
                }
                name="EnableCRM"
                label="Enable eSS"
                labelPlacement="start"
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                sx={{ width: "100%", justifyContent: "space-between", ml: "0" }}
                control={
                  <Switch
                    checked={dealer?.EnableEndUserAdvisorSelection}
                    value={dealer?.EnableEndUserAdvisorSelection}
                    onChange={checkedChangedHandler}
                  />
                }
                name="EnableEndUserAdvisorSelection"
                label="Enable End User Advisor Selection"
                labelPlacement="start"
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                sx={{ width: "100%", justifyContent: "space-between", ml: "0" }}
                control={
                  <Switch
                    checked={dealer?.EnableMPVI}
                    value={dealer?.EnableMPVI}
                    disabled={true}
                    onChange={checkedChangedHandler}
                  />
                }
                name="EnableMPVI"
                label="Enable MPVI"
                labelPlacement="start"
              />
            </Grid>
          </Grid>
        </form>
      )}
    </>
  );
};
