import React, { useState } from "react";
import {
  Box,
  Button,
  Divider,
  FormControl,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
  IconButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import { dealerHoliday } from "api/types/dealerHoliday";
import { useAppSelector } from "store";
import postHoliday from "api/postHolidays";
import removeHoliday from "api/removeHoliday";

const sortHolidaysByDate = (holidays: dealerHoliday[]) =>
  holidays
    .slice()
    .sort((a, b) => dayjs(a.HolidayDate).diff(dayjs(b.HolidayDate)));

const DealerHolidaySection = () => {
  const { holidays, dealerHolidays, dealer } = useAppSelector(
    (state) => state.dealerForm
  );

  const [holidaysList, setHolidaysList] = useState<dealerHoliday[]>(
    sortHolidaysByDate(dealerHolidays)
  );

  const [newHoliday, setNewHoliday] = useState<dealerHoliday>({
    ID: 0,
    DealerID: dealer.ID,
    HolidayName: "",
    HolidayNameID: 0,
    HolidayDate: "",
  });

  const [errors, setErrors] = useState<{ name: boolean; date: boolean }>({
    name: false,
    date: false,
  });

  const handleAddHoliday = () => {
    if (newHoliday.HolidayNameID === 0 || !newHoliday.HolidayDate) {
      setErrors({
        name: newHoliday.HolidayNameID === 0,
        date: !newHoliday.HolidayDate,
      });
      return;
    }

    const newHolidayEntry: dealerHoliday = {
      ID: Math.max(...holidaysList.map((h) => h.ID), 0) + 1,
      DealerID: dealer.ID,
      HolidayNameID: newHoliday.HolidayNameID,
      HolidayDate: newHoliday.HolidayDate,
      HolidayName: newHoliday.HolidayName,
    };

    setHolidaysList((prevList) =>
      sortHolidaysByDate([...prevList, newHolidayEntry])
    );
    setNewHoliday({
      ID: 0,
      DealerID: dealer.ID,
      HolidayName: "",
      HolidayNameID: 0,
      HolidayDate: "",
    });
    setErrors({ name: false, date: false });
    postHoliday(newHolidayEntry);
  };

  const handleDeleteHoliday = (holidayToDelete: dealerHoliday) => {
    setHolidaysList((prevList) =>
      sortHolidaysByDate(
        prevList.filter((holiday) => holiday.ID !== holidayToDelete.ID)
      )
    );
    removeHoliday(holidayToDelete);
  };

  const handleHolidayNameChange = (
    event: SelectChangeEvent<number | string>
  ) => {
    const selectedID = Number(event.target.value);
    const selectedHoliday = holidays.find((option) => option.ID === selectedID);

    if (selectedHoliday) {
      setNewHoliday((prev) => ({
        ...prev,
        HolidayName: selectedHoliday.Name,
        HolidayNameID: selectedHoliday.ID,
      }));
    }
  };

  const handleDateChange = (date: Dayjs | null) => {
    if (date) {
      setNewHoliday((prev) => ({
        ...prev,
        HolidayDate: date.format("YYYY-MM-DD"),
      }));
    }
  };

  return (
    <Box
      display="flex"
      justifyContent="flex-start"
      alignItems="flex-start"
      p={2}
    >
      <Box
        p={2}
        maxWidth={400}
        width="100%"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          boxShadow: 3,
          borderRadius: 1,
        }}
      >
        <Typography variant="h6" gutterBottom>
          Select Holidays
        </Typography>
        <Divider sx={{ mb: 2 }} />

        <Stack spacing={2} mb={2} width="100%">
          <FormControl fullWidth error={errors.name}>
            <Select
              label="Select Holiday"
              value={newHoliday.HolidayNameID || ""}
              onChange={handleHolidayNameChange}
              displayEmpty
              renderValue={(value) => {
                const selectedHoliday = holidays.find(
                  (option) => option.ID === Number(value)
                );
                return selectedHoliday
                  ? selectedHoliday.Name
                  : "Select or enter new holiday";
              }}
            >
              {holidays.map((holiday) => (
                <MenuItem key={holiday.ID} value={holiday.ID}>
                  {holiday.Name}
                </MenuItem>
              ))}
              <MenuItem value="">Add New Holiday...</MenuItem>
            </Select>
            <TextField
              label="New Holiday Name"
              value={newHoliday.HolidayName}
              onChange={(e) =>
                setNewHoliday((prev) => ({
                  ...prev,
                  HolidayName: e.target.value,
                }))
              }
              sx={{ mt: 1 }}
            />
            {errors.name && (
              <Typography color="error">Holiday name is required</Typography>
            )}
          </FormControl>

          <FormControl fullWidth error={errors.date}>
            <DatePicker
              label="Holiday Date"
              value={
                newHoliday.HolidayDate ? dayjs(newHoliday.HolidayDate) : null
              }
              onChange={handleDateChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  helperText={errors.date ? "Holiday date is required" : ""}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: errors.date ? "red" : undefined,
                      },
                    },
                    "& .MuiFormLabel-root": {
                      color: errors.date ? "red" : undefined,
                    },
                  }}
                />
              )}
            />
          </FormControl>

          <Button
            variant="contained"
            sx={{
              backgroundColor: "#1976d2",
              "&:hover": { backgroundColor: "#115293" },
            }}
            onClick={handleAddHoliday}
          >
            Add Holiday
          </Button>
        </Stack>
        <Box sx={{ maxHeight: 400, overflowY: "auto", width: "100%" }}>
          <List>
            {holidaysList.map(({ ID, HolidayName, HolidayDate }) => (
              <ListItem key={ID} dense>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  width="100%"
                  alignItems="center"
                >
                  <ListItemText
                    primary={HolidayName}
                    secondary={dayjs(HolidayDate).format("MMMM D, YYYY")}
                  />
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() =>
                      handleDeleteHoliday({
                        ID,
                        HolidayName,
                        HolidayDate,
                        DealerID: dealer.ID,
                        HolidayNameID: 0,
                      })
                    }
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              </ListItem>
            ))}
          </List>
        </Box>
      </Box>
    </Box>
  );
};

export default DealerHolidaySection;
