import { AdvisorAvailability } from "api/types/advisorAvailability";
import apiFetch from "api/utils/APIFetch";

const postAdvisorSchedule = (advisorAvailability: AdvisorAvailability) : Promise<void> => apiFetch(`/api/web-scheduler/advisor-availability`, {
    method: `POST`,
    body: JSON.stringify(advisorAvailability)
}).catch(error => {
    throw new Error(error.Message)
})

export default postAdvisorSchedule;