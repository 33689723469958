import {
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import React, { useCallback, useState } from "react";
import { useAppDispatch, useAppSelector } from "store";
import setDates from "../../thunks/setDates";
import useSubscribeToSchedulingOverview from "components/Scheduling/hooks/useSubscribeToSchedulingOverview";
import moment from "moment";
import { US_DATE_FORMAT } from "constants/date.constants";
import setTimePeriod from "components/Scheduling/thunks/setTimePeriod";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import AppointmentSetup from "components/Scheduling/components/AppointmentSetup";
const localizer = momentLocalizer(moment);

interface SelectedSlot {
  start: Date;
  end: Date;
}

function SchedulingDashboard() {
  const dispatch = useAppDispatch();
  useSubscribeToSchedulingOverview();

  const { startDate, endDate, appointments, timePeriod } = useAppSelector(
    (state) => state.scheduling
  );
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedSlot, setSelectedSlot] = useState<SelectedSlot | null>(null);

  const handleAppointmentCreated = () => {
    setOpenDialog(false);
    setSelectedSlot(null);
  };

  const onTimeRangeApplied = useCallback(
    (time) => {
      dispatch(setTimePeriod({ startDate, endDate, timePeriod: time }));
    },
    [dispatch, startDate, endDate]
  );

  const onDateRangeApplied = useCallback(
    (date) => {
      let startMoment = moment(date);
      let endMoment = moment(date);

      if (timePeriod === "week") {
        startMoment = moment(date).day(0);
        endMoment = moment(date).day(6);
      }
      if (timePeriod === "month") {
        startMoment = moment(date).date(1);
        endMoment = moment(date).add(1, "month").date(1).subtract(1, "day");
      }

      dispatch(
        setDates({
          startDate: startMoment.format(US_DATE_FORMAT),
          endDate: endMoment.format(US_DATE_FORMAT),
        })
      );
    },
    [dispatch, timePeriod]
  );

  const events = appointments.map((appt) => ({
    start: moment(appt.Date).toDate(),
    end: moment(appt.Date).add(15, "minutes").toDate(),
    title: `${appt.Lead.FirstName} ${appt.Lead.LastName} ${appt.Lead.Phone}`,
  }));

  const handleSelectSlot = useCallback(({ start, end }) => {
    setSelectedSlot({ start, end });
    setOpenDialog(true);
  }, []);

  const handleDialogClose = () => {
    setOpenDialog(false);
    setSelectedSlot(null);
  };

  const handleSelectEvent = useCallback((event) => {
    console.log("Selected event:", event);
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid container item spacing={2} xs={12}>
        <Grid item xs={12}>
          <Calendar
            localizer={localizer}
            date={startDate}
            min={new Date(1972, 0, 0, 7, 0, 0)}
            max={new Date(2072, 0, 0, 19, 0, 0)}
            step={15}
            timeslots={2}
            view={timePeriod}
            events={events}
            style={{ height: "100vh" }}
            onNavigate={onDateRangeApplied}
            onView={onTimeRangeApplied}
            onSelectSlot={handleSelectSlot}
            onSelectEvent={handleSelectEvent}
            selectable
          />
        </Grid>
      </Grid>

      <Dialog open={openDialog} onClose={handleDialogClose}>
        <DialogTitle>Schedule Appointment</DialogTitle>
        <DialogContent>
          {selectedSlot && (
            <>
              <p>
                Selected Start:{" "}
                {moment(selectedSlot.start).format("MMMM Do YYYY, h:mm a")}
              </p>
              <p>
                Selected End:{" "}
                {moment(selectedSlot.end).format("MMMM Do YYYY, h:mm a")}
              </p>
              <AppointmentSetup
                initialStartTime={selectedSlot.start}
                initialEndTime={selectedSlot.end}
                onAppointmentCreated={handleAppointmentCreated}
              />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleDialogClose();
            }}
            color="primary"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}

export default SchedulingDashboard;
