import { AppointmentViewModel } from "api/types/appointmentViewModel";
import apiFetch from "api/utils/APIFetch";

const postAppointment = async ( appointment: AppointmentViewModel): Promise<void> => {
    try {
        await apiFetch(`/api/web-scheduler/ess/appointments`, {
            method: `POST`,
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(appointment ),
        });
    } catch (error) {
        throw new Error((error as Error).message || "An error occurred while posting the appointment");
    }
};

export default postAppointment;
