import React from "react";
import styles from "./styles.module.scss";
import { SingleServiceLine } from "classes/ServiceLine";
import { Checkbox } from "@mui/material";
import { DEFAULT_PRICING_COMMENT } from "constants/pricing.constants";
import { SchedulerMaintenanceLineItemStatus } from "api/types/appointmentScheduleLineItem";
import { CleanUtility } from "utils/CleanUtility";
import { FormatUtility } from "utils/FormatUtility";
import { useDispatch } from "react-redux";
import { updateAcceptedLineItems } from "modules/appointment";
import { useAppSelector } from "store";

interface ISelectableLineItemProps {
  serviceLine: SingleServiceLine;
}

const SelectableLineItem = ({ serviceLine }: ISelectableLineItemProps) => {
  const dispatch = useDispatch();
  const { lineItems } = useAppSelector((state) => state.appointment);

  const sameServiceAlreadyChecked = lineItems.find(
    (item) =>
      item.Name === serviceLine.description &&
      item.Interval !== serviceLine.distance &&
      item.Status === SchedulerMaintenanceLineItemStatus.Accepted
  );

  const itemAcceptedAlready = lineItems.find(
    (item) =>
      item.Name === serviceLine.description &&
      item.Interval === serviceLine.distance
  );

  const onCheckedChanged = (
    _: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    const tempLineItems = [...lineItems];

    if (checked) {
      const fixedPrice =
        serviceLine.price === "" ? 0 : parseFloat(serviceLine.price);

      tempLineItems.push({
        ID: 0,
        Comment: CleanUtility.Comment(fixedPrice, serviceLine.comment),
        Interval: serviceLine.distance,
        Name: serviceLine.description,
        Price: fixedPrice,
        Source: serviceLine.source,
        Status: SchedulerMaintenanceLineItemStatus.Accepted,
      });
    } else {
      const index = tempLineItems.findIndex(
        (li) =>
          li.Name === serviceLine.description &&
          li.Interval === serviceLine.distance &&
          li.Source === serviceLine.source
      );
      if (index !== -1) {
        tempLineItems.splice(index, 1);
      }
    }

    dispatch(updateAcceptedLineItems(tempLineItems));
  };

  const getDisplayText = (singleServiceLine: SingleServiceLine) => {
    const parsePrice = parseFloat(singleServiceLine.price);
    if (parsePrice > 0) {
      return FormatUtility.currency(singleServiceLine.price);
    }

    if (singleServiceLine.comment === "") {
      return DEFAULT_PRICING_COMMENT;
    }

    return singleServiceLine.comment;
  };

  return (
    <React.Fragment key={serviceLine.id}>
      <Checkbox
        className={styles.selectableService}
        size="small"
        color="primary"
        checked={itemAcceptedAlready !== undefined}
        onChange={onCheckedChanged}
        disabled={sameServiceAlreadyChecked !== undefined}
      />
      <div>{serviceLine.description}</div>
      <div className={styles.price}>{getDisplayText(serviceLine)}</div>
    </React.Fragment>
  );
};

export default SelectableLineItem;
