import React from 'react';
import styles from './styles.module.scss';
import classNames from 'classnames';
import { GroupServiceLine, ServiceLine, SingleServiceLine } from 'classes/ServiceLine';
import SelectableLineItem from 'components/SelectableLineItem';

type Props = {
    title: string;
    listItems: ServiceLine[];
};

function SectionDetail({ title, listItems }: Props) { 
    const isGroupItem = (item: ServiceLine): item is GroupServiceLine => 
        (item as GroupServiceLine).title !== undefined;

    const singleItems = listItems.filter(item => !isGroupItem(item));
    const groupItems = listItems.filter(isGroupItem);

    const singleItemContent = (singleItems: SingleServiceLine[]): JSX.Element => {
        const items = singleItems.map(item => (
            <SelectableLineItem key={item.id} serviceLine={item}  />
        ));

        return (
            <div className={classNames(styles.sectionGrid, styles.threeColumnGrid)}>
                {items}
            </div>
        );
    };

    const groupItemContent = (groupItems: GroupServiceLine[]): JSX.Element[] => {
        return groupItems.map(entry => {
            const items = entry.items.map(li => (
                <SelectableLineItem key={li.id} serviceLine={li} />
            ));

            return (
                <React.Fragment key={entry.id}>
                    <div className={styles.sectionTitle}>{entry.title}</div>
                    <div className={classNames(styles.intervalGroup, styles.sectionGrid, styles.threeColumnGrid)}>
                        {items}
                    </div>
                </React.Fragment>
            );
        });
    };

    const content = singleItems.length > 0
        ? singleItemContent(singleItems as SingleServiceLine[])
        : groupItemContent(groupItems as GroupServiceLine[]);

    return (
        <div className={styles.container}>
            <div className={styles.title}>{title}</div>
            <div>{content}</div>
        </div>
    );
}

export default SectionDetail;
