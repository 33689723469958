import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Customer } from "api/types/customer";
import { AppThunk } from "store";
import { ICustomerSearchRequest } from "types/customer";
import searchCustomer from "api/customers/searchCustomer";

interface ICustomerSearchView {
  isSearching: boolean;
  isError: boolean;
  results: Customer[];
  helperText: string;
}

let initialState: ICustomerSearchView = {
  isSearching: false,
  isError: false,
  results: [],
  helperText: ``,
};

const slice = createSlice({
  name: `customerSearchView`,
  initialState,
  reducers: {
    searchStart(state) {
      state.isSearching = true;
      state.isError = false;
      state.helperText = initialState.helperText;
    },
    searchSuccess(state, action: PayloadAction<Customer[]>) {
      state.isSearching = false;
      state.isError = false;
      state.helperText = initialState.helperText;
      state.results = action.payload;
    },
    searchFailure(state, action: PayloadAction<string>) {
      state.isSearching = false;
      state.isError = true;
      state.helperText = action.payload;
      state.results = [];
    },
    reset: (state) => initialState,
  },
});

export const { searchStart, searchSuccess, searchFailure, reset } =
  slice.actions;

export default slice.reducer;

export interface ICustomerSearchPayload {
  searchBy: string;
  search: string;
}

export const searchForCustomer =
  (payload: ICustomerSearchPayload): AppThunk =>
    async (dispatch, getState) => {
      try {
        dispatch(searchStart());

        const { ID: dealerID } = getState().dealer;

        const results = await searchCustomer(payload.searchBy, {
          SearchTerm: payload.search,
        } as ICustomerSearchRequest, dealerID);

        dispatch(searchSuccess(results));
      } catch (err: any) {
        dispatch(searchFailure(err.Message));
      }
    };
