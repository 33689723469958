import apiFetch from "api/utils/APIFetch";
import { dealerHoliday } from "api/types/dealerHoliday";

const removeHoliday = (dealerHoliday: dealerHoliday) : Promise<void> => apiFetch(`/api/web-scheduler/holidays`, {
    method: `DELETE`,
    body: JSON.stringify(dealerHoliday)
}).catch(error => {
    throw new Error(error.Message)
})

export default removeHoliday