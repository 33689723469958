import React from "react";
import AdvisorUserInfoSection from "components/AdvisorForm/AdvisorUserInfoSection";
import AdvisorScheduleSection from "components/AdvisorForm/AdvisorScheduleSection";

const AdvisorAccountForm = () => {
  return (
    <div>
      <h1>Your Account</h1>
      <div>
        <AdvisorUserInfoSection />
      </div>
      <h1>Your Schedule</h1>
      <div>
        <AdvisorScheduleSection />
      </div>
    </div>
  );
};

export default AdvisorAccountForm;
