import moment from 'moment'
import Base from 'classes/Base'
import Service from 'classes/Service'
import Recall from 'classes/Recall'
import { appointmentScheduleLineItem } from 'api/types/appointmentScheduleLineItem'

export default class Appointment extends Base {
    id: number | null = null
    advisorName: string = `Any Advisor`
    vehicleID: string | null = null
    vehicleDesc: string = ``
    vehicleImageURL: string = ``
    vehicleMileage: string = ``
    vin: string = ``
    transportationID: number | null = null
    transportation: string = ``
    services: Array<Service> = []
    serviceDesc: string = ``
    recalls: Array<Recall> = []
    timeSlotID: number | null = null
    rawDateTime: string = ``
    confirmationCode: string = ``
    userID: number | null = null
    modelID: number | null = null
    readonly time: string
    readonly date: string
    lineItems: appointmentScheduleLineItem[] = []

    constructor(init?:Partial<Appointment>) {
        super()

        Object.assign(this, init)
        this.time = this.rawDateTime !== `` ? formatTime(this.rawDateTime) : ``
        this.date = this.rawDateTime !== `` ? formatDate(this.rawDateTime) : ``
    }

}

export function formatDate(dateTime: string) {
    return moment(dateTime).format(`MMMM Do, YYYY`)
}

export function formatTime(dateTime: string) {
    return moment(dateTime).format(`h:mm a`)
}