import React from 'react'
import { useAppDispatch, useAppSelector } from 'store'
import AccountCircle from '@mui/icons-material/AccountCircle'
import Person from '@mui/icons-material/Person'
import { Box, Divider, ListItemIcon, MenuItem, MenuList, Popover, Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material'
import { logOut } from 'modules/login'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import styles from './styles.module.scss'
import { Logout } from '@mui/icons-material'
import { User } from 'api/types/user'
import { Dealer } from 'api/types/dealer'
import { Role } from 'api/types/role'
import DealerPicker from './components/DealerPicker'
import AdvisorAccountForm from 'components/AdvisorAccountForm'

function UserMenu() {
    const dispatch = useAppDispatch()
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const open = Boolean(anchorEl);

    const user: User = useAppSelector(state => state.user)
    const dealer: Dealer = useAppSelector(state => state.dealer)
    
    const handleSignOutClick = () => {
        dispatch(logOut())
    }

    const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    }

    const handleMenuClose = () => {
        setAnchorEl(null);
        window.location.reload();
    }

    const handleViewAccountClick = () => {
        setDialogOpen(true);
    }

    const handleCloseDialog = () => {
        setDialogOpen(false);
    }

    return (
        <React.Fragment>
            <Box className={styles.accountContainer}>
                <Box className={styles.accountInfo}>
                    <span>{user.FirstName} {user.LastName}</span>
                    <span>{dealer.Name}</span>
                </Box>
                <Box 
                    className={styles.accountMenu}
                    onClick={handleMenuOpen}>
                    <AccountCircle fontSize={`large`} />
                    <ExpandMoreIcon />
                </Box>
            </Box>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleMenuClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}>
                <MenuList dense>
                    <Box className={styles.accountDetailsContainer}>
                        <Box>
                            <AccountCircle />
                        </Box>
                        <Box>
                            <span>{user.FirstName} {user.LastName}</span>
                            <span>{user.Email}</span>
                            <span>{dealer.Name}</span>
                        </Box>
                    </Box>
                    <Divider />
                    {user.Role === Role.Admin && (
                        <DealerPicker data={dealer} closeMenu={handleMenuClose} />
                    )}
                    <MenuItem onClick={handleViewAccountClick}>
                        <ListItemIcon>
                            <Person fontSize="small" />
                        </ListItemIcon>
                        View your account
                    </MenuItem>
                    <MenuItem onClick={handleSignOutClick}>
                        <ListItemIcon>
                            <Logout fontSize="small" />
                        </ListItemIcon>
                        Logout
                    </MenuItem>
                </MenuList>
            </Popover>
            <Dialog
                open={dialogOpen}
                onClose={handleCloseDialog}
                maxWidth="md"
                fullWidth
            >
                <DialogTitle>View Your Account</DialogTitle>
                <DialogContent>
                    <AdvisorAccountForm />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}

export default UserMenu
