import { dealerHoliday } from "api/types/dealerHoliday";
import apiFetch from "api/utils/APIFetch";

const postHoliday = (dealerHoliday: dealerHoliday) : Promise<void> => apiFetch(`/api/web-scheduler/holidays`, {
    method: `POST`,
    body: JSON.stringify(dealerHoliday)
}).catch(error => {
    throw new Error(error.Message)
})

export default postHoliday;