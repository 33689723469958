import apiFetch from "api/utils/APIFetch";

const updateDealerAmenities = (dealerID: number, amenityIds: number[]): Promise<void> => {
    return apiFetch(`/api/amenities/dealers/${dealerID}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(amenityIds),
    }).catch(error => {
        throw new Error(error.message);
    });
};

export default updateDealerAmenities;
