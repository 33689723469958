import React, { useState, useEffect } from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import styles from "./styles.module.scss";
import { useAppSelector } from "store";
import updateDealerAmenities from "api/updateDealerAmenities";

const AmenitySection = () => {
  const { amenities, dealerAmenities, dealer } = useAppSelector(
    (state) => state.dealerForm
  );
  const [selectedAmenityIds, setSelectedAmenityIds] = useState<number[]>([]);

  useEffect(() => {
    const foundAmenities = dealerAmenities
      .map((item) => item.ID)
      .filter((id) => amenities.some((amenity) => amenity.ID === id));

    if (foundAmenities.length > 0) {
      setSelectedAmenityIds(foundAmenities);
    }
  }, [dealer.ID, dealerAmenities, amenities]);

  const handleAmenityChange = async (event: SelectChangeEvent<number[]>) => {
    const value = event.target.value as number[];
    setSelectedAmenityIds(value);
    await updateDealerAmenities(dealer.ID, value);
  };

  return (
    <div className={styles.container}>
      <FormControl fullWidth>
        <InputLabel id="amenity-select-label">Select Amenities</InputLabel>
        <Select
          labelId="amenity-select-label"
          id="amenity-select"
          multiple
          value={selectedAmenityIds}
          onChange={handleAmenityChange}
          renderValue={(selected) => {
            const selectedNames = amenities
              .filter((amenity) => selected.includes(amenity.ID))
              .map((amenity) => amenity.Name);
            return selectedNames.join(", ");
          }}
          className={styles.dropdown}
        >
          {amenities.map((amenity) => (
            <MenuItem key={amenity.ID} value={amenity.ID}>
              <input
                type="checkbox"
                checked={selectedAmenityIds.includes(amenity.ID)}
                readOnly
              />
              {amenity.Name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default AmenitySection;
