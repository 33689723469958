import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import Appointment from 'classes/Appointment'
import Vehicle from 'classes/Vehicle'
import { appointmentScheduleLineItem } from 'api/types/appointmentScheduleLineItem'

interface VehicleInfoPayload extends Omit<Vehicle, 'id'> { }

let initialState: Appointment = new Appointment().toPlainObj()

const slice = createSlice({
    name: `appointment`,
    initialState,
    reducers: {
        set(state, action: PayloadAction<Appointment>) {
            return action.payload
        },
        update(state, action: PayloadAction<Partial<Appointment>>) {
            state = Object.assign(state, action.payload)
        },
        setVehicleInfo(state, action: PayloadAction<VehicleInfoPayload>) {
            const { description, imageURL, mileage, vin } = action.payload
            state.vehicleDesc = description
            state.vehicleImageURL = imageURL
            state.vehicleMileage = mileage
            state.vin = vin
        },
        updateAcceptedLineItems(state, action: PayloadAction<appointmentScheduleLineItem[]>)
        {
            state.lineItems = [...action.payload]
        },
        reset(state) {
            return initialState
        }
    }
})

export const {
    set,
    update,
    setVehicleInfo,
    updateAcceptedLineItems,
    reset
} = slice.actions

export default slice.reducer