import React, { useState, useEffect } from "react";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
  Box,
  CircularProgress,
} from "@mui/material";

import getDealers from "api/getDealers";
import getMakesByYear from "api/getMakesByYear";
import getDealerServices from "api/getDealerServices";
import getRecallServices from "api/getRecallServices";
import getMaintenanceServices from "api/getMaintenanceServices";
import getDealerAdvisors from "api/getDealerAdvisors";
import getTransportOptions from "api/getTransportOptions";

import { useAppSelector } from "store";
import { useDispatch } from "react-redux";
import { Advisor } from "api/types/advisor";
import { TransportOption } from "api/types/transportOption";
import { setMaintenanceServices } from "modules/maintenance";
import {
  GroupServiceLine,
  ServiceLine,
  SingleServiceLine,
} from "classes/ServiceLine";

import buildDealerMaintenanceSection from "utils/buildDealerMaintenanceSection";
import buildFactoryMaintenanceSection from "utils/buildFactoryMaintenanceSection";
import buildIolmMaintenanceSection from "utils/buildIolmMaintenanceSection";
import SectionDetail from "components/SectionDetail";
import postAppointment from "api/postAppointment";
import { LeadVehicle } from "api/types/leadVehicle";
import { Lead } from "api/types/lead";
import {
  AppointmentServiceStatus,
  AppointmentSource,
  AppointmentViewModel,
  DealerService,
  Service,
} from "api/types/appointmentViewModel";
import { RecallResponse, RecallResult } from "api/types/recallService";
import { Dealer } from "api/types/dealer";

interface VehicleMake {
  name: string;
  models: VehicleModel[];
}

interface VehicleModel {
  id: number;
  name: string;
  variations: { colorName: string }[];
}

interface AppointmentSetupProps {
  initialStartTime?: Date;
  initialEndTime?: Date;
  onAppointmentCreated: () => void;
}

const AppointmentSetup = (props: AppointmentSetupProps) => {
  const { initialStartTime } = props;
  const { dealer } = useAppSelector((state) => state.dealerForm);
  const { ID } = useAppSelector((state) => state.user);
  const agentID = ID;
  const { lineItems } = useAppSelector((state) => state.appointment);
  const [customerFirstName, setCustomerFirstName] = useState<string>("");
  const [customerLastName, setCustomerLastName] = useState<string>("");
  const [customerEmail, setCustomerEmail] = useState<string>("");
  const [customerPhone, setCustomerPhone] = useState<string>("");
  const [contactViaEmail, setContactViaEmail] = useState<boolean>(false);
  const [contactViaText, setContactViaText] = useState<boolean>(false);

  const [selectedYear, setSelectedYear] = useState<number | 0>(0);
  const [selectedMake, setSelectedMake] = useState<string | "">("");
  const [selectedModel, setSelectedModel] = useState<string | "">("");
  const [selectedColor, setSelectedColor] = useState<string | "">("");
  const [selectedRecalls, setSelectedRecalls] = useState<RecallResult[]>([]);
  const [vehicleMileage, setVehicleMileage] = useState<number | "">("");
  const [availableMakes, setAvailableMakes] = useState<VehicleMake[]>([]);
  const [availableModels, setAvailableModels] = useState<VehicleModel[]>([]);
  const [availableColors, setAvailableColors] = useState<string[]>([]);

  const [loadingServices, setLoadingServices] = useState<boolean>(false);
  const [isAppointmentCreated, setIsAppointmentCreated] = useState(false);

  const [dealers, setDealers] = useState<Dealer[]>([]);
  const [dealerServices, setDealerServices] = useState<Service[]>([]);
  const [selectedDealer, setSelectedDealer] = useState<number | "">("");
  const [selectedServices, setSelectedServices] = useState<Service[]>([]);
  const [selectedTransportation, setSelectedTransportation] = useState<
    number | ""
  >("");
  const [transportationOptions, setTransportationOptions] = useState<
    TransportOption[]
  >([]);
  const [dealerAdvisors, setDealerAdvisors] = useState<Advisor[]>([]);
  const [selectedAdvisor, setSelectedAdvisor] = useState<number | "">("");
  const [recallServices, setRecallServices] = useState<RecallResult[]>([]);
  const [alwaysItems, setAlwaysItems] = useState<ServiceLine[]>([]);
  const [factoryItems, setFactoryItems] = useState<GroupServiceLine[]>([]);
  const [dealerItems, setDealerItems] = useState<GroupServiceLine[]>([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchDealers = async () => {
      try {
        const dealers = await getDealers();
        setLoadingServices(true);
        const sortedDealers = dealers.sort((a, b) =>
          a.Name.localeCompare(b.Name)
        );
        setDealers(sortedDealers);
      } catch (error) {
        console.error("Error fetching dealers:", error);
        setDealers([]);
      } finally {
        setLoadingServices(false);
      }
    };
    fetchDealers();
  }, []);

  useEffect(() => {
    const fetchRecallServices = async () => {
      if (!selectedYear || !selectedMake || !selectedModel) {
        return;
      }
      try {
        const recalls: RecallResponse = await getRecallServices(
          selectedYear,
          selectedMake,
          selectedModel
        );
        setLoadingServices(true);
        if (recalls.results) {
          setRecallServices(recalls.results);
        } else {
          setRecallServices([]);
        }
      } catch (error) {
        console.error("Error fetching vehicle recalls:", error);
        setRecallServices([]);
      } finally {
        setLoadingServices(false);
      }
    };

    fetchRecallServices();
  }, [selectedYear, selectedMake, selectedModel]);

  useEffect(() => {
    const fetchDealerAdvisors = async () => {
      try {
        const advisors = await getDealerAdvisors(selectedDealer);
        setLoadingServices(true);
        const sortedAdvisors = advisors.sort((a, b) =>
          a.FullName.localeCompare(b.FullName)
        );
        setDealerAdvisors(sortedAdvisors);
      } catch (error) {
        console.error("Error fetching dealer advisors:", error);
      } finally {
        setLoadingServices(false);
      }
    };

    fetchDealerAdvisors();
  }, [selectedDealer]);

  useEffect(() => {
    const fetchTransportationOptions = async () => {
      try {
        const options = await getTransportOptions();
        setLoadingServices(true);
        setTransportationOptions(options);
      } catch (error) {
        console.error("Error fetching transportation options:", error);
      } finally {
        setLoadingServices(false);
      }
    };

    fetchTransportationOptions();
  }, []);

  useEffect(() => {
    const fetchMaintenanceServices = async () => {
      if (selectedMake && selectedModel && selectedYear && vehicleMileage) {
        try {
          const selectedModelObj = availableModels.find(
            (model) => model.name === selectedModel
          );
          const modelId = selectedModelObj ? selectedModelObj.id : 0;
          const maintenanceLineItems = await getMaintenanceServices(
            dealer.ID,
            selectedYear,
            modelId,
            vehicleMileage
          );
          setLoadingServices(true);
          const iolmMaintenance = buildIolmMaintenanceSection(
            maintenanceLineItems.oemServices.always
          );
          setAlwaysItems(iolmMaintenance);

          const factoryMaintenance = buildFactoryMaintenanceSection(
            maintenanceLineItems.oemServices
          );
          setFactoryItems(factoryMaintenance);

          const dealerMaintenance = buildDealerMaintenanceSection(
            maintenanceLineItems.dealerServices
          );
          setDealerItems(dealerMaintenance);

          let iolmServices = iolmMaintenance.map(
            (service) => service as SingleServiceLine
          );
          let factoryServices = factoryMaintenance
            .map((group) => group.items)
            .flat()
            .map((service) => service as SingleServiceLine);
          let dealerServices = dealerMaintenance
            .map((group) => group.items)
            .flat()
            .map((service) => service as SingleServiceLine);

          dispatch(
            setMaintenanceServices([
              ...iolmServices,
              ...factoryServices,
              ...dealerServices,
            ])
          );
        } catch (error) {
          console.error("Error fetching maintenance services:", error);
        } finally {
          setLoadingServices(false);
        }
      }
    };

    fetchMaintenanceServices();
  }, [
    dealer.ID,
    selectedMake,
    selectedModel,
    selectedYear,
    vehicleMileage,
    availableModels,
    dispatch,
  ]);

  useEffect(() => {
    if (selectedYear && typeof selectedYear === "number") {
      let isMounted = true;
      const fetchMakes = async () => {
        try {
          const makes = await getMakesByYear(selectedYear);
          setLoadingServices(true);
          if (isMounted) {
            setAvailableMakes(
              makes.sort((a, b) => a.name.localeCompare(b.name))
            );
            setSelectedMake("");
            setSelectedModel("");
            setAvailableModels([]);
            setAvailableColors([]);
            setDealerServices([]);
          }
        } catch (error) {
          console.error("Error fetching makes:", error);
        } finally {
          setLoadingServices(false);
        }
      };

      fetchMakes();
      return () => {
        isMounted = false;
      };
    }
  }, [selectedYear]);

  useEffect(() => {
    if (selectedMake) {
      const selectedMakeObj = availableMakes.find(
        (make) => make.name === selectedMake
      );
      if (selectedMakeObj) {
        setAvailableModels(
          selectedMakeObj.models.sort((a, b) => a.name.localeCompare(b.name))
        );
        setSelectedModel("");
        setAvailableColors([]);
        setDealerServices([]);
      }
    }
  }, [selectedMake, availableMakes]);

  useEffect(() => {
    if (selectedModel && selectedYear && typeof selectedYear === "number") {
      const selectedModelObj = availableModels.find(
        (model) => model.name === selectedModel
      );
      if (selectedModelObj) {
        setAvailableColors(
          selectedModelObj.variations.map((variation) => variation.colorName)
        );

        let isMounted = true;
        const fetchDealerServices = async () => {
          try {
            const services = await getDealerServices(
              selectedDealer,
              selectedModelObj.id,
              selectedYear
            );
            setLoadingServices(true);
            if (isMounted) {
              setDealerServices(services);
            }
          } catch (error) {
            console.error("Error fetching dealer services:", error);
          } finally {
            setLoadingServices(false);
          }
        };

        fetchDealerServices();
        return () => {
          isMounted = false;
        };
      }
    }
  }, [selectedModel, availableModels, selectedYear, selectedDealer]);

  const handleYearChange = (event: SelectChangeEvent<number | "">) => {
    setSelectedYear(event.target.value as number);
  };

  const handleMakeChange = (event: SelectChangeEvent<string | "">) => {
    setSelectedMake(event.target.value as string);
  };

  const handleModelChange = (event: SelectChangeEvent<string | "">) => {
    setSelectedModel(event.target.value as string);
    setSelectedColor("");
  };

  const handleColorChange = (event: SelectChangeEvent<string | "">) => {
    setSelectedColor(event.target.value as string);
  };

  const handleMileageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.replace(/,/g, "");
    setVehicleMileage(value ? Number(value) : "");
  };

  const handleCheckboxChange = (recall: RecallResult) => {
    setSelectedRecalls((prev) =>
      prev.some(
        (selectedRecall) =>
          selectedRecall.NHTSACampaignNumber === recall.NHTSACampaignNumber
      )
        ? prev.filter(
            (n) => n.NHTSACampaignNumber !== recall.NHTSACampaignNumber
          )
        : [...prev, recall]
    );
  };

  const formatMileage = (mileage: number | "") => {
    if (typeof mileage === "number") {
      return mileage.toLocaleString();
    }
    return "";
  };

  const handleServiceChange = (service: Service) => {
    setSelectedServices((prevSelectedServices) => {
      const isSelected = prevSelectedServices.some(
        (selectedService) => selectedService.id === service.id
      );
      if (isSelected) {
        return prevSelectedServices.filter(
          (selectedService) => selectedService.id !== service.id
        );
      } else {
        return [...prevSelectedServices, service];
      }
    });
  };

  const handleTransportationChange = (transportationId: number) => {
    setSelectedTransportation(transportationId);
  };

  function convertServicesToDealerServices(
    services: Service[]
  ): DealerService[] {
    return services.map((service) => ({
      id: service.id,
      text: service.description,
      status: AppointmentServiceStatus.Accepted,
    }));
  }

  const isFormValid = () => {
    return (
      customerFirstName &&
      customerLastName &&
      customerEmail &&
      customerPhone &&
      selectedYear &&
      selectedMake &&
      selectedModel &&
      selectedColor &&
      vehicleMileage
    );
  };

  const handleSubmit = () => {
    if (!isFormValid()) {
      alert("Please fill out all required fields.");
      return;
    }
    const selectedModelObj = availableModels.find(
      (model) => model.name === selectedModel
    );
    const modelId = selectedModelObj ? selectedModelObj.id : 0;
    const user: Lead = {
      ID: agentID.toString(),
      Email: customerEmail,
      EmailOptIn: contactViaEmail,
      FirstName: customerFirstName,
      LastName: customerLastName,
      Phone: customerPhone,
      TextOptIn: contactViaText,
      CustomerID: 0,
    };

    const userVehicle: LeadVehicle = {
      ID: "",
      LeadID: "",
      Year: selectedYear,
      ModelID: modelId,
      Miles: vehicleMileage,
      Color: selectedColor,
      Vin: "",
      BrandName: selectedMake,
      ModelName: selectedModel,
    };

    const userAppointment: AppointmentViewModel = {
      lead: user,
      vehicle: userVehicle,
      appointment: {
        id: 1,
        advisorId: selectedAdvisor,
        agentId: Number(user.ID),
        cancelLink: "",
        confirmationCode: "",
        appointmentInterimId: selectedTransportation,
        date: initialStartTime,
        dealerId: selectedDealer,
        isCancelled: false,
        issueDescription: "",
        leadId: "",
        leadVehicleId: "",
        timeSlotId: 0,
        modelId: modelId,
        BrandName: selectedMake,
        ModelName: selectedModel,
        color: selectedColor,
        year: selectedYear,
        appointmentSource: AppointmentSource.DealerDashboard,
        recalls: selectedRecalls,
        services: convertServicesToDealerServices(selectedServices),
        lineItems: lineItems,
      },
    };

    try {
      setIsAppointmentCreated(false);
      postAppointment(userAppointment);
      setIsAppointmentCreated(true);
    } catch (error) {
      console.error("Error creating appointment:", error);
      alert("Failed to create appointment. Please try again.");
    }
  };

  if (isAppointmentCreated) {
    return (
      <Box>
        <Typography variant="h6" color="success.main">
          Appointment created successfully!
        </Typography>
      </Box>
    );
  }

  const currentYear = new Date().getFullYear();
  const yearOptions = Array.from(
    { length: currentYear - 1994 + 1 },
    (_, i) => currentYear - i
  );

  const sections = [
    {
      title: `Every Oil Life Monitor Cycle`,
      listItems: alwaysItems,
    },
    {
      title: `Factory Required Maintenance`,
      listItems: factoryItems,
    },
    {
      title: `Dealer Recommended Maintenance`,
      listItems: dealerItems,
    },
  ].filter((section) => section.listItems.length > 0);

  return (
    <Box padding={2}>
      <Box marginBottom={2}>
        <Box marginBottom={2}>
          <Typography variant="h6">Dealer</Typography>
          {dealers.length > 0 ? (
            <FormControl fullWidth>
              <InputLabel id="dealer-select-label">Select a Dealer</InputLabel>
              <Select
                labelId="dealer-select-label"
                value={selectedDealer}
                onChange={(e) => setSelectedDealer(e.target.value as number)}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 48 * 6 + 8,
                      width: 250,
                    },
                  },
                }}
              >
                {dealers.map((dealer) => (
                  <MenuItem key={dealer.ID} value={dealer.ID}>
                    {dealer.Name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : (
            <Typography>No dealers available</Typography>
          )}
        </Box>
        <Typography variant="h6">Select Vehicle</Typography>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <FormControl fullWidth>
              <InputLabel>Year</InputLabel>
              <Select
                value={selectedYear}
                onChange={handleYearChange}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 200,
                      overflowY: "auto",
                    },
                  },
                }}
              >
                <MenuItem value="">
                  <em>Select Year</em>
                </MenuItem>
                {yearOptions.map((year) => (
                  <MenuItem key={year} value={year}>
                    {year}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl fullWidth>
              <InputLabel>Make</InputLabel>
              <Select
                value={selectedMake}
                onChange={handleMakeChange}
                disabled={!selectedYear}
              >
                <MenuItem value="">
                  <em>Select Make</em>
                </MenuItem>
                {availableMakes.map((make) => (
                  <MenuItem key={make.name} value={make.name}>
                    {make.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl fullWidth>
              <InputLabel>Model</InputLabel>
              <Select
                value={selectedModel}
                onChange={handleModelChange}
                disabled={!selectedMake}
              >
                <MenuItem value="">
                  <em>Select Model</em>
                </MenuItem>
                {availableModels.map((model) => (
                  <MenuItem key={model.name} value={model.name}>
                    {model.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl fullWidth>
              <InputLabel>Color</InputLabel>
              <Select
                value={selectedColor}
                onChange={handleColorChange}
                disabled={!selectedModel}
              >
                <MenuItem value="">
                  <em>Select Color</em>
                </MenuItem>
                {availableColors.map((color) => (
                  <MenuItem key={color} value={color}>
                    {color}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Box>

      <Box marginBottom={2}>
        <TextField
          label="Mileage"
          type="text"
          fullWidth
          value={formatMileage(vehicleMileage)}
          onChange={handleMileageChange}
          InputProps={{
            inputProps: { min: 0 },
          }}
        />
      </Box>
      <Box marginBottom={2}>
        <Typography variant="h6">Dealer Services</Typography>
        {loadingServices ? (
          <CircularProgress />
        ) : (
          <FormGroup>
            {dealerServices.length > 0 ? (
              dealerServices.map((service) => (
                <FormControlLabel
                  key={service.id}
                  control={
                    <Checkbox
                      checked={selectedServices.some(
                        (selectedService) => selectedService.id === service.id
                      )}
                      onChange={() => handleServiceChange(service)}
                    />
                  }
                  label={service.description}
                />
              ))
            ) : (
              <Typography>
                No dealer services available for the selected vehicle.
              </Typography>
            )}
          </FormGroup>
        )}
      </Box>

      <Box marginBottom={2}>
        <Typography variant="h6">Recall Services</Typography>
        {recallServices.length > 0 ? (
          <Box>
            {recallServices.map((recall) => (
              <FormControlLabel
                key={recall.NHTSACampaignNumber}
                control={
                  <Checkbox
                    checked={selectedRecalls.some(
                      (selectedRecall) =>
                        selectedRecall.NHTSACampaignNumber ===
                        recall.NHTSACampaignNumber
                    )}
                    onChange={() => handleCheckboxChange(recall)}
                  />
                }
                label={
                  <Box padding={1} border={1} borderColor="grey.300">
                    <Typography variant="subtitle1">
                      {recall.Component}
                    </Typography>
                    <Typography variant="body2">
                      Issue Date:{" "}
                      {new Date(recall.ReportReceivedDate).toLocaleDateString()}
                    </Typography>
                    <Typography variant="body2">{recall.Summary}</Typography>
                  </Box>
                }
              />
            ))}
          </Box>
        ) : (
          <Typography>
            No recall services available for the selected vehicle.
          </Typography>
        )}
      </Box>

      <Box marginBottom={2}>
        <Typography variant="h6">Maintenance Services</Typography>
        <FormGroup>
          {sections.map((section, index) => (
            <React.Fragment key={`maintenance-section-${index}`}>
              <SectionDetail
                title={section.title}
                listItems={section.listItems}
              />
              {index < sections.length - 1 && <hr />}
            </React.Fragment>
          ))}
        </FormGroup>
      </Box>

      <Box marginBottom={2}>
        <Typography variant="h6">Select Dealer Advisor</Typography>
        <FormControl fullWidth>
          <InputLabel>Advisor</InputLabel>
          <Select
            value={selectedAdvisor}
            onChange={(e) => setSelectedAdvisor(e.target.value as number)}
          >
            <MenuItem value="">
              <em>Select Advisor</em>
            </MenuItem>
            {dealerAdvisors.map((advisor) => (
              <MenuItem key={advisor.ID} value={advisor.ID}>
                {advisor.FullName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <Box marginBottom={2}>
        <Typography variant="h6">Transportation Options</Typography>
        <FormGroup>
          {transportationOptions.length > 0 ? (
            transportationOptions.map((transportation) => (
              <FormControlLabel
                key={transportation.ID}
                control={
                  <Checkbox
                    checked={selectedTransportation === transportation.ID}
                    onChange={() =>
                      handleTransportationChange(transportation.ID)
                    }
                  />
                }
                label={transportation.Description}
              />
            ))
          ) : (
            <Typography>Loading transportation options...</Typography>
          )}
        </FormGroup>
      </Box>

      <Box marginBottom={2}>
        <Typography variant="h6">Customer Information</Typography>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <TextField
              label="First Name"
              fullWidth
              value={customerFirstName}
              onChange={(e) => setCustomerFirstName(e.target.value)}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              label="Last Name"
              fullWidth
              value={customerLastName}
              onChange={(e) => setCustomerLastName(e.target.value)}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              label="Email"
              type="email"
              fullWidth
              value={customerEmail}
              onChange={(e) => setCustomerEmail(e.target.value)}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              label="Phone Number"
              type="tel"
              fullWidth
              value={customerPhone}
              onChange={(e) => setCustomerPhone(e.target.value)}
            />
          </Grid>
        </Grid>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={contactViaEmail}
                onChange={(e) => setContactViaEmail(e.target.checked)}
              />
            }
            label="Yes I want to receive reminders and updates via Email."
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={contactViaText}
                onChange={(e) => setContactViaText(e.target.checked)}
              />
            }
            label="Yes I want to receive reminders and updates via text."
          />
        </FormGroup>
      </Box>

      <Button
        variant="contained"
        color="primary"
        onClick={handleSubmit}
        disabled={isAppointmentCreated}
      >
        Create Appointment
      </Button>
    </Box>
  );
};

export default AppointmentSetup;
