export namespace FormatUtility {
    export function currency(value: string): string {
      var parsedValue = parseFloat(value)
  
      if (Number.isNaN(parsedValue)) {
        return ``
      }
  
      return new Intl.NumberFormat(`en-US`, {
        style: `currency`,
        currency: `USD`,
      }).format(parsedValue)
    }
  
    export function number(value: number): string {
      return new Intl.NumberFormat().format(value)
    }
  }