import { ServiceLine } from "classes/ServiceLine"
import { SchedulerMaintenanceLineItemSource } from "api/types/appointmentScheduleLineItem"
import { OemServiceItem } from "api/types/maintenanceResponse"


export default function buildIolmMaintenanceSection(services: OemServiceItem[]): ServiceLine[]{
    return services.map(item => {
        const {
          id,
          description
        } = item.oemServiceItem
    
        return {
          id,
          description,
          price: item.pricing.value,
          comment: item.pricing.comment,
          distance: item.distance,
          source: SchedulerMaintenanceLineItemSource.IOLM
        }
      })
}


