export type appointmentScheduleLineItem = {
    ID: number,
    Comment: string,
    Interval: number,
    Name: string,
    Price: number,
    Source: SchedulerMaintenanceLineItemSource,
    Status: SchedulerMaintenanceLineItemStatus
}

export enum SchedulerMaintenanceLineItemSource {
    IOLM,
    FACTORY,
    DEALER
}

export enum SchedulerMaintenanceLineItemStatus {
    Undecided,
    Accepted,
    Declined
}