import React, { useState, useEffect } from "react";
import {Box, MenuItem, Select, TextField, Typography, Button} from "@mui/material";
import { DealerAdvisorAvailability } from "api/types/dealerAdvisorAvailability";
import { useAppSelector } from "store";
import postAdvisorSchedule from "api/postAdvisorSchedule";

const daysOfWeek = [ "Monday","Tuesday", "Wednesday", "Thursday", "Friday","Saturday","Sunday"];

const DaySchedule = ({
  day,
  schedule,
  onChange,
}: {
  day: keyof Omit<DealerAdvisorAvailability, "ID" | "UserID" | "AdvisorName">;
  schedule: DealerAdvisorAvailability;
  onChange: (
    day: keyof Omit<DealerAdvisorAvailability, "ID" | "UserID" | "AdvisorName">,
    field: "StartTime" | "EndTime",
    value: string
  ) => void;
}) => {
  const startTimeKey = `${day}StartTime` as keyof DealerAdvisorAvailability;
  const endTimeKey = `${day}EndTime` as keyof DealerAdvisorAvailability;

  const startTimeValue =
    schedule[startTimeKey] === "00:00:00" ? "" : schedule[startTimeKey];
  const endTimeValue =
    schedule[endTimeKey] === "00:00:00" ? "" : schedule[endTimeKey];

  const isFaded =
    schedule[startTimeKey] === "00:00:00" &&
    schedule[endTimeKey] === "00:00:00";

  return (
    <Box sx={{ mb: 2, opacity: isFaded ? 0.5 : 1 }}>
      <Typography variant="h6">{day}</Typography>
      <Box sx={{ display: "flex", gap: 2, alignItems: "center", mt: 1 }}>
        <TextField
          type="time"
          label="Start Time"
          value={startTimeValue}
          onChange={(e) => onChange(day, "StartTime", e.target.value)}
          size="small"
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          type="time"
          label="End Time"
          value={endTimeValue}
          onChange={(e) => onChange(day, "EndTime", e.target.value)}
          size="small"
          InputLabelProps={{ shrink: true }}
        />
      </Box>
    </Box>
  );
};

const DealerAdvisorScheduleConfiguration = () => {
  const [selectedAdvisor, setSelectedAdvisor] = useState<number | null>(null);
  const [schedule, setSchedule] = useState<DealerAdvisorAvailability | null>(
    null
  );
  const { dealerAdvisorSchedule } = useAppSelector((state) => state.dealerForm);

  useEffect(() => {
    if (selectedAdvisor !== null) {
      const advisorSchedule = dealerAdvisorSchedule.find(
        (schedule) => schedule.UserID === selectedAdvisor
      );
      setSchedule(advisorSchedule || null);
    }
  }, [selectedAdvisor, dealerAdvisorSchedule]);

  const handleChange = (
    day: keyof Omit<DealerAdvisorAvailability, "ID" | "UserID" | "AdvisorName">,
    field: "StartTime" | "EndTime",
    value: string
  ) => {
    if (schedule) {
      const startTimeKey = `${day}StartTime` as keyof DealerAdvisorAvailability;
      const endTimeKey = `${day}EndTime` as keyof DealerAdvisorAvailability;

      setSchedule({
        ...schedule,
        [startTimeKey]: field === "StartTime" ? value : schedule[startTimeKey],
        [endTimeKey]: field === "EndTime" ? value : schedule[endTimeKey],
      });
    }
  };

  const handleSave = () => {
    if (selectedAdvisor && schedule) {
      const updatedSchedule = {
        ...schedule,
        ID: selectedAdvisor,
        AdvisorName:
          dealerAdvisorSchedule.find(
            (advisor) => advisor.UserID === selectedAdvisor
          )?.AdvisorName || "",
      };

      postAdvisorSchedule(updatedSchedule)
        .then((response) => {
          console.log("Schedule saved successfully:", response);
        })
        .catch((error) => {
          console.error("Error saving schedule:", error);
        });
    }
  };

  return (
    <Box sx={{ p: 4 }}>
      <Select
        value={selectedAdvisor || ""}
        onChange={(e) => setSelectedAdvisor(Number(e.target.value))}
        displayEmpty
        sx={{ mb: 4 }}
      >
        <MenuItem value="" disabled>
          Select Advisor
        </MenuItem>
        {dealerAdvisorSchedule.map((advisor) => (
          <MenuItem key={advisor.UserID} value={advisor.UserID}>
            {advisor.AdvisorName}
          </MenuItem>
        ))}
      </Select>

      {selectedAdvisor && (
        <Typography variant="h6" sx={{ mb: 2 }}>
          {
            dealerAdvisorSchedule.find(
              (advisor) => advisor.UserID === selectedAdvisor
            )?.AdvisorName
          }
          's Schedule
        </Typography>
      )}

      {schedule ? (
        daysOfWeek.map((day) => {
          const dayKey = day as keyof Omit<
            DealerAdvisorAvailability,
            "ID" | "UserID" | "AdvisorName"
          >;
          return (
            <DaySchedule
              key={`${schedule.ID}-${day}`}
              day={dayKey}
              schedule={schedule}
              onChange={handleChange}
            />
          );
        })
      ) : (
        <Typography>No schedule available</Typography>
      )}

      {schedule && (
        <Button
          onClick={handleSave}
          variant="contained"
          color="primary"
          sx={{ mt: 2 }}
        >
          Update Schedule
        </Button>
      )}
    </Box>
  );
};

export default DealerAdvisorScheduleConfiguration;
