
import { GroupServiceLine } from "classes/ServiceLine"
import { SchedulerMaintenanceLineItemSource } from "api/types/appointmentScheduleLineItem"
import { OemServiceItem, Service, ServiceIntervalItem } from "api/types/maintenanceResponse"


export default function buildFactoryMaintenanceSection({
    previous,
    current,
    future,
  }: Service<OemServiceItem>): GroupServiceLine[] {
    const groups = [
      previous,
      current,
      future,
    ]
  
    const mapGroup = (group: ServiceIntervalItem<OemServiceItem>): GroupServiceLine => {
      const interval = group.interval;
  
      const items = group.items.map(item => ({
        id: item.oemServiceItem.id,
        description: item.oemServiceItem.description,
        price: item.pricing.value,
        comment: item.pricing.comment,
        distance: interval,
        source: SchedulerMaintenanceLineItemSource.FACTORY
      }))
  
      return {
        id: group.interval,
        title: `${new Intl.NumberFormat().format(group.interval)} Miles`,
        items,
      }
    }
  
    return groups.map(mapGroup).filter(group => group.id > 0 && group.items.length > 0)
  }