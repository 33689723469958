import { appointmentScheduleLineItem } from "./appointmentScheduleLineItem";
import { Lead } from "api/types/lead";
import { LeadVehicle } from "api/types/leadVehicle";
import { RecallResult } from "./recallService";

export interface AppointmentViewModel {
    lead: Lead; 
    vehicle: LeadVehicle; 
    appointment: {
        id?: number;
        advisorId?: number | "";
        agentId?: number | string;
        cancelLink?: string;
        confirmationCode?: string;
        appointmentInterimId?: number | "";
        date?: Date;
        dealerId?: number | "";
        isCancelled: boolean;
        issueDescription?: string;
        leadId?: string;
        leadVehicleId?: string;
        timeSlotId?: number;
        modelId: number;
        BrandName: string;
        ModelName: string;
        color?: string;
        year: number;
        appointmentSource: AppointmentSource;
        recalls?: RecallResult[];
        services?: DealerService[];
        lineItems?: appointmentScheduleLineItem[];
    };
}

export enum AppointmentSource {
    WebScheduler = 0,
    DealerDashboard = 1
}

export interface Recall {
    id?: number;
    appointmentId?: number;
    campaignNumber: string;
    component: string;
    summary: string;
}

export interface Service {
    id: number;
    description: string;
    status: AppointmentServiceStatus;
}

export interface DealerService {
    id: number;
    text: string;
    status: AppointmentServiceStatus;
}

export enum AppointmentServiceStatus {
    Undecided,
    Accepted,
    Declined
}

export interface LineItem {
    id: number;
    appointmentId?: number;
    comment: string;
    interval: number;
    price: number;
    source: MaintenanceLineItemSource;
    status: MaintenanceLineItemStatus;
    name: string;
}

export enum MaintenanceLineItemSource {
    IOLM,
    FACTORY,
    DEALER
}

export enum MaintenanceLineItemStatus {
    Undecided,
    Accepted,
    Declined
}
