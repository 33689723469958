import { Customer } from "api/types/customer"
import apiFetch from "api/utils/APIFetch"
import { ICustomerSearchRequest } from 'types/customer'

const searchService = (searchType: string, request: ICustomerSearchRequest, dealerId: number): Promise<Customer[]> => 
  apiFetch(`/api/customers/search/${searchType}/${dealerId}`, {
    method: `POST`,
    body: JSON.stringify(request)
  })

export default searchService