import { DEFAULT_PRICING_COMMENT } from "constants/pricing.constants"

export namespace CleanUtility {
    export function Comment(price: number, comment: string | null) {
        if (price > 0) {
            return comment ?
                comment :
                ``
        }

        return comment ?
            (comment === `` ? DEFAULT_PRICING_COMMENT : comment) :
            DEFAULT_PRICING_COMMENT // comment is null
    }
}