import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { SingleServiceLine } from 'classes/ServiceLine'
import { MaintenanceResponse } from 'api/types/maintenanceResponse'

export interface MaintenanceState {
    maintenanceResponse: MaintenanceResponse,
    maintenanceServices: SingleServiceLine[]
}

let initialState = {
    maintenanceResponse: {} as MaintenanceResponse,
    maintenanceServices: [] as SingleServiceLine[]
} as MaintenanceState

const slice = createSlice({
    name: `maintenance`,
    initialState,
    reducers: {
        setMaintenance(state, action: PayloadAction<MaintenanceResponse>) {
            state.maintenanceResponse = Object.assign(state.maintenanceResponse, action.payload)
        },
        setMaintenanceServices(state, action: PayloadAction<SingleServiceLine[]>) {
            state.maintenanceServices = Object.assign(state.maintenanceServices, action.payload)
        },
        reset(state) {
            return initialState
        }
    }
})

export const {
    setMaintenance,
    setMaintenanceServices,
    reset
} = slice.actions

export default slice.reducer