import React, { useEffect, useState } from "react";
import { AdvisorAvailability } from "api/types/advisorAvailability";
import { Box, Button, TextField, Typography, CircularProgress } from '@mui/material';
import getAdvisorAvailability from "api/getAdvisorAvailability";
import postAdvisorSchedule from "api/postAdvisorSchedule";
import { useAppSelector } from "store";

const daysOfWeek = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

const DaySchedule = ({
  day,
  schedule,
  onChange,
}: {
  day: keyof Omit<AdvisorAvailability, "ID" | "UserID">;
  schedule: AdvisorAvailability;
  onChange: (
    day: keyof Omit<AdvisorAvailability, "ID" | "UserID">,
    field: "StartTime" | "EndTime",
    value: string
  ) => void;
}) => {
  const startTimeKey = `${day}StartTime` as keyof AdvisorAvailability;
  const endTimeKey = `${day}EndTime` as keyof AdvisorAvailability;

  const startTime = schedule[startTimeKey];
  const endTime = schedule[endTimeKey];

  return (
    <Box sx={{ mb: 2 }}>
      <Typography variant="h6">{day}</Typography>
      <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', mt: 1 }}>
        <TextField
          type="time"
          label="Start Time"
          value={startTime}
          onChange={(e) => onChange(day, "StartTime", e.target.value)}
          size="small"
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          type="time"
          label="End Time"
          value={endTime}
          onChange={(e) => onChange(day, "EndTime", e.target.value)}
          size="small"
          InputLabelProps={{ shrink: true }}
        />
      </Box>
    </Box>
  );
};

const AdvisorScheduleSection = () => {
  const { ID } = useAppSelector((state) => state.user);
  const [schedule, setSchedule] = useState<AdvisorAvailability | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchSchedule = async () => {
      try {
        const response = await getAdvisorAvailability(ID);
        setSchedule(response);
      } catch (err) {
        setError("Failed to fetch schedule.");
      } finally {
        setLoading(false);
      }
    };

    fetchSchedule();
  }, [ID]);

  const handleChange = (
    day: keyof Omit<AdvisorAvailability, "ID" | "UserID">,
    field: "StartTime" | "EndTime",
    value: string
  ) => {
    if (schedule) {
      const startTimeKey = `${day}StartTime` as keyof AdvisorAvailability;
      const endTimeKey = `${day}EndTime` as keyof AdvisorAvailability;

      setSchedule((prevSchedule) => ({
        ...prevSchedule!,
        [startTimeKey]: field === "StartTime" ? value : prevSchedule![startTimeKey],
        [endTimeKey]: field === "EndTime" ? value : prevSchedule![endTimeKey],
      }));
    }
  };

  const handleSave = async () => {
    if (schedule) {
      console.log("Saving schedule:", schedule);
      await postAdvisorSchedule(schedule);
    }
  };

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  return (
    <Box sx={{ p: 4 }}>
      {daysOfWeek.map((day) => (
        <DaySchedule
          key={day}
          day={day as keyof Omit<AdvisorAvailability, "ID" | "UserID">}
          schedule={schedule!}
          onChange={handleChange}
        />
      ))}
      <Button
        onClick={handleSave}
        variant="contained"
        color="primary"
        sx={{ mt: 2 }}
      >
        Save
      </Button>
    </Box>
  );
};

export default AdvisorScheduleSection;
